.toolkit{
    width: 100%;
    height: 100%;

    overflow-y: scroll;
    position: relative;
}


/************************
*        VIDEO          *
*************************/
.toolkit__backgroundVideo{
    width: 100%;
    height: 100%;
    overflow: hidden;

    position: relative;
}
.toolkit__backgroundVideo video{
    z-index: 1;
}
.toolkit__backgroundVideo__wrapper{
    width: 100%;
    height: 20%;

    margin-top: 20vw;

    position: absolute;
    color: white;
    /* mix-blend-mode: difference; */
    z-index: 4;

    display: flex;
    justify-content: center;

}
.toolkit__backgroundVideo__title{
    padding: 1vw;
}
.toolkit__backgroundVideo__title h1{
    font-size: var(--font-a-size-h1);
    font-family: var(--font-a-family-h1);

}
.toolkit__backgroundVideo__title h2{
    font-size: var(--font-a-size-h2);
    font-family: var(--font-a-family-h2);
    font-style: italic;
    font-weight: 400;

}


/************************
*       ARTICLE         *
*************************/
.toolkit__container{
    position: relative;
    height: auto;
}

.toolkit__container__title{
    text-align: center;
    padding: 10vh 0;
}

.toolkit__container__title h1{
    color: black;
    font-size: var(--font-a-size-body-h1);
    font-family: var(--font-a-family-h1);

}
.toolkit__container__title h2{
    margin-top:  2vh;
    font-size: var(--font-a-size-body-h2);
    font-family: var(--font-a-family-h2);
    font-style: italic;
    font-weight: 400;
}

.toolkit__container__intro{
    padding: 0 25vw 0 25vw;
    margin-top: 1vw;
    margin-bottom: 7.5vw;

}
.toolkit__container__intro img{
    width: 100%;

}
.toolkit p{
    font-size: 1vw;
    font-family: 'garamond';
    font-style: italic;
}
.toolkit__intro__text{
    padding: 4.6vw 0 0 0!important;

    font-size: 2vw!important;
    font-family: 'garamond';
    font-style: italic;   
    text-align: center; 
}

.toolkit__container__bigImg{
    padding-bottom: 4vw;
}
.toolkit__big__img{
    width: 100%;
}

.toolkit__container__body{
    display: flex;
    height: fit-content;
}

.toolkit__container__body > *{
    width: 50%;
}

.toolkit__body__text{
    height: fit-content;
    padding: 2.5vw 10vw 7.5vw 10vw;
    /* background-color: red; */
}

.toolkit__container__body h2{
    margin-top: 3.4vw;
    margin-bottom: 1vw;

    font-size: var(--font-a-size-body-h2);
    font-family: var(--font-a-family-body-h2);
    font-weight: 400;
}
.toolkit__container__body div{
    margin-bottom: 1vw;

    font-size: 1.3vw;
    font-family: 'Garamond';
    font-weight: 400;
}

.toolkit__body__image {
    height: auto;

    display: flex;
    justify-content: center;


}
.toolkit__body__image img{
    height: 35vw;

    padding-top: 4vw;
    padding-bottom: 7.5vw;

    position: -webkit-sticky;
    position: sticky;
    top: 0;

}


.toolkit__body__text__quote{
    width: 100%;
    padding: 0vw 10vw 5vw 10vw;
}
.toolkit__body__text__quote h2{
    font-size: 5vw;
    font-family: var(--font-a-family-quote);
    font-style: italic;
    font-weight: 400;
    text-align: center;
    width: 100%;

    text-shadow: yellow 1px 0 10px;
}

.toolkit__body__text__middle_img{
    width: 100%;
    padding: 2.5vw 20vw 7.5vw 20vw;

    display: flex;
    justify-content: space-between;


}

.toolkit__body__text__middle_img img{
    height: 40vw;
    padding: 1vw;
    width: auto;
    
}

.toolkit__body__text__quote p{
    margin-left: 5vw;
    margin-top: 2.5vw;
}


.toolkit__body__text__middle{
    width: 100%;
    padding: 2.5vw 30vw 7.5vw 30vw;
}
.toolkit__body__text__middle h2{
    margin-top: 2.5vw;
    margin-bottom: 1vw;

    font-size: var(--font-a-size-body-h2);
    font-family: var(--font-a-family-body-h2);
    font-weight: 400;
}
.toolkit__body__text__middle h2:nth-child(1){
    margin-top: 0vh;
}

.toolkit__body__text__middle p{
    margin-bottom: 1.5vw;

}

.toolkit__body__text__middle li{
    font-family: 'EB Garamond';
    font-size: 1.2vw;
    margin-left: 2vw;
}

.toolkit__container__imgRow{
    width: 99.9%;
    display: flex;
    flex-direction: row;

    border-color: black;
    border-style: solid;
    border-width: 0.5px;
    overflow: hidden;
}
.toolkit__container__imgRow img{
    height: 31.15vw;
}





.toolkit__body__specialText{
    margin-left: 4vw;
    font-family: 'acumin-variable'!important;
    font-style: italic!important;
    font-size: 1.1vw!important;
    font-weight: 500!important;
}

.toolkit__body__image__opencall img{
    height: 20vw;
}


.toolkit__download__button{
    padding: 1vw 2vw;
    border-radius: 20px;
    width: fit-content;
    background-color: black;


}


.toolkit__download__button a{
    color: white!important;
    font-family: 'EB Garamond', serif;
    font-style: italic;

}

.toolkit__container__swiper{
    width: 98vw;
    /* margin-left: 6vw;     */
    padding-bottom: 5vw;
}
.toolkit__container__swiper_s{

}
.toolkit__container__swiper img{
    height: 40vw;
    width: auto;
}

.toolkit .swiper-wrapper{
    margin-left: 3.5vw;
}


.toolkit .swiper{
    width: 100%;
    height: 100%;
    
    background-color: transparent!important;
    opacity: 1!important;

}
.toolkit .swiper-button-next::after{
    color: black;
    font-size: 1.5vw!important;
    margin-left: 1.5vw;

}

.toolkit .swiper-button-prev::after{
    color: black;
    font-size: 1.5vw!important;
    font-weight: 500;
    margin-left: 2vw;
    /* transform: rotate(90deg);
    margin-top: -0.1vw; */


}


@media only screen and (max-width: 450px) {
    /****************************
    *        displayNone        *
    ****************************/
    .marker__container{
        display: none;
    }

    .toolkit__backgroundVideo{
        width: 100%;
        height: fit-content;
        overflow: hidden;
    
        position: relative;
        display: flex;
        justify-content: center;
    }
    .toolkit__backgroundVideo video{
        width: auto;
        height: 100vh;
    }
    .toolkit__backgroundVideo__wrapper{   
        margin-top: 45vh;
    }

    .toolkit__backgroundVideo__title h1{
        font-size: 5vw;
    }
    .toolkit__backgroundVideo__title h2{
        font-size: 4vw;
    }


    /****************************
    *          Changes          *
    ****************************/
    .toolkit{
        width: 103%;
        overflow-x: hidden;
    }
    .toolkit__container__title{
        margin-top: 7vh;
        margin-left: 6vw;
        padding: 0;
        margin-bottom: 5vw;
        text-align: center;
        width: fit-content;
    }
    .toolkit__container__title h1{
        font-size: 8vw;
        margin-bottom: 3vw;
    }
    .toolkit__container__title h2{
        font-size: 4vw!important;
        font-family: "EB Garamond"!important;
        margin-top: 0!important;
    }

    .toolkit__container__intro{
        padding: 0 6vw;
        margin-bottom: 6vw;
    }

    .toolkit__container__intro > *{
        width: 100%;
    }
    .toolkit__intro__text{
        font-size: 4vw!important;
    }

    /* .singleInsitu__container__body > *{
        width: 50%;
        background-color: antiquewhite;
    } */
    .toolkit__body__text{
        width: 60%;
    }
    .toolkit__body__image{
        width: 40%;
    }

    .toolkit__body__text{
        height: fit-content;
        padding: 5vh 6vw 10vh 6vw;
        /* background-color: red; */
    }

    .toolkit__container h2{
        margin-top: 7vh;
        margin-bottom: 2vh;

        font-size: 6vw;
    }
    .toolkit__body__text h2:nth-child(1){
        margin-top: 0vh;
    }
    .toolkit__container h3{
        font-size: 5vw;
    }
    .toolkit__body__text div{
        margin-bottom: 0.7vh;

        font-size: 4vw;
    }
    .toolkit__body__image img{
        height: 25vh;

    }

    .toolkit__body__text__middle{
        width: 100%;
        padding: 5vh 15vw 15vh 15vw;
    }
    .toolkit__body__text__middle{
        margin-bottom: 0.7vh;

        font-size: 4vw!important;
    }

    .toolkit__body__text__middle h2{
        font-size: 5vw;
    }
    .toolkit__body__text__middle p{
        margin-bottom: 4vw;

        font-size: 2.5vw;
    }

    .toolkit__body__text__quote{
        width: 100%;
        padding: 0vh 10vw  10vw 10vw;
    }

    .toolkit__body__specialText{
        font-size: 3vw!important;
    }
    .toolkit__body__image__opencall{
        display: none;
    }

    .toolkit__body__text__mobile{
        width: 100%;
    }

    .toolkit p{
        font-size: 2.5vw;
    }

    /*changes first*/
    .toolkit__workshop__container{
        height: 530vw;
    }
    
    .toolkit__workshop__blur__img{
        width: 333%;
        margin-left: -50vw;
    }

    .toolkit__workshop__images__1, .toolkit__workshop__images__2, .toolkit__workshop__images__3, .toolkit__workshop__images__4{
        margin-left: 75vw;
    }
    .toolkit__workshop__images{
        margin-top: 30vw;
    }
    .toolkit__workshop__images__1{
        margin-top: 280vw;

    }
    .toolkit__workshop__images__2{
        margin-top: 330vw;
    }
    .toolkit__workshop__images__3{
        margin-top: 395vw;
    }
    .toolkit__workshop__images__4{
        margin-top: 460vw;
    }
    
    .toolkit__workshop__images__6{
        margin-top: 100vw;
    }
    .toolkit__workshop__images__7{
        margin-top: 155vw;
    }
    .toolkit__workshop__images__8{
        margin-top: 210vw;
    }

    .toolkit__container .toolkit__workshop__h2{
        font-size: 5vw!important;
    }

    .toolkit__workshop__subtitle, .toolkit__workshop__subtext, .toolkit__workshop__subtitle2, .toolkit__workshop__subtext2{
        font-size: 3vw!important;
    } 

    /*changes second*/

    .toolkit__workshop__container{
        position: relative;
        display: block;


        overflow: hidden;

        /* background-color: blue; */
    }

    .toolkit__workshop__container img{
        position: absolute;
    }

    .toolkit__workshop__blur__img{
        z-index: -1;

        filter: blur(8px);
        -webkit-filter: blur(10px);

    }

    .toolkit__container__workshop{
        width: 100%;

        display: block;
        flex-flow: column;
        align-items: center!important;

        /* padding: 5vh 0 0 0!important; */

        /* background-color: red; */


    }

    .toolkit__body__text__workshop{
    width: 74%;

    display: flex;
    flex-flow: column;
    align-items: center!important;

        margin-left: 5vw;
    }

    .toolkit__container__workshop > *{
        color: white;
        text-shadow: black 1px 0 4px;
        

    }
    .toolkit__body__text__workshop h2{
        font-family: 'adelphe'!important;
        margin-bottom: 4vw!important;
    }

    .toolkit__workshop__subtitle{
        font-family: 'adelphe'!important;

        width: 75%;

    }
    .toolkit__workshop__subtext{
        margin-left: 2vw;
        font-family: 'acumin-variable'!important;
        margin-bottom: 5vw!important;

    }

    .toolkit__container__toolkit__workshop__text{
        width: 64%;
        margin-left: 5vw;

    }

    .toolkit__container__toolkit__workshop__text h2{
        margin-bottom: 5vw;
    }

    .toolkit__workshop__subtitle2{
        font-family: 'adelphe'!important;

        /* width: 75%; */

    }

    .toolkit__workshop__subtext2{
        font-family: 'acumin-variable'!important;
        margin-bottom: 5vw!important;

    }

    .credit__margin{
        font-size: 2vw!important;
        margin-top: 0.1vh;
    }
}



