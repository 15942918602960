.insitus{
    width: 100%;
    height: 100%;

    position: relative;
    overflow: scroll;

    overflow-x: hidden;

}
.insitus .insitus__title{
    margin-top: 5vh;
    margin-left: 8vw;
    position: fixed;

    font-size: 2.5vw;

    font-family: "adelphe"


}
.insitus__renders__containers{
    height: auto;
    width: 100%;

    /* background-color: red; */

    display: flex;
    flex-flow: column;
}
.insitus canvas{
    z-index: 1;
}

.insitus__container{
    width: 30vw;
    margin-left: 65vw;
    top: 0;


    position: absolute;
    z-index: 3;
}

.insitus .insitus__section{
    z-index: 2;
    height: 100vh;

    /* background-color: red; */
    display: flex;
    align-items: center;
    /* position: relative; */

    /* background-color: blue; */

}
.insitus .insitus__wrapper{
    width: 25vw;
    padding: 2vw;

    border-color: black;
    border-style: solid;
    border-width: 2px;

    justify-content: flex-end;

}

.insitus .insitus__wrapper h1{
    margin-bottom: 0.5vh;
    font-family: var(--font-a-family-h1);
    font-size: var(--font-i-size-h1);

}

.insitus .insitus__wrapper h2{
    font-style: italic;
    font-family: var(--font-a-family-h2);
    font-size: var(--font-i-size-h2);

    font-weight: 400;

}

.insitus__video__containers{
    display: none;
}

.webgl{
    height: 100%;
}
@media only screen and (max-width: 450px) {
    .mobile{
        display: block;
    }


    /****************************
    *        displayNone        *
    ****************************/
    .renderhide{
        display: none;
    }

    /****************************
    *          Changes          *
    ****************************/

    .insitus .insitus__title{
        margin-top: 2vh;
        margin-left: 15vw;
        font-size: 7vw;

        z-index: 6;
    }

    .insitus__container{
        width: 70vw;
        margin-left: 15vw;
        margin-top: 8vh;
    }
    
    .insitus .insitus__section{
        height: 100vh;    
    }
    .insitus .insitus__wrapper{
        width: 60vw;
        padding: 4vw;
        border: none;

        backdrop-filter: blur(10px) saturate(70%);

        border-color: white;
        border-style: solid;
        border-width: 2px;
    

    }

    .insitus .insitus__wrapper h1{
        font-size: 6vw; 
        color: white;

    }
    
    .insitus .insitus__wrapper h2{
        margin-top: 1vh;
        font-size: 4vw;    
        color: white!important;

    }
    .insitus__video__containers{
        width: 101%;
        overflow: hidden;
        display: block;
        /* margin-top: 8vh; */

        position: absolute;

        display: flex;
        flex-direction: column;
        justify-content: center;
    }
    .video__wrapper{
        width: 100%;
        height: 100vh;
        overflow: hidden;
        display: flex;
        justify-content: center;
    }
    .video__wrapper img{
        width: auto;
        height: 100%;
    }
}

